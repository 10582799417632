// SPDX-FileCopyrightText: © 2017 EteSync Authors
// SPDX-License-Identifier: AGPL-3.0-only

export const appName = "EteSync";
export const defaultServerUrl = "https://api.safesync.cc/";
export const homePage = "https://www.etesync.com/";
export const faq = homePage + "faq/";
export const pricing = homePage + "pricing/";
export const getApps = homePage + "get-apps/";
export const terms = homePage + "tos/";
export const sourceCode = "https://github.com/etesync/etesync-web";
export const reportIssue = sourceCode + "/issues";

export const forgotPassword = "https://www.etesync.com/faq/#forgot-password";
